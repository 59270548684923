<template>
    <div class="Profile-main w-100">
        <div class="Profile-main_left">
            <div class="Profile-main_photo white-block" v-loading="loadingService">
                <p class="photo_title">{{ $t('profile.profile.avatar') }}</p>
                <div class="position-relative" v-if="this.avatarImage">
                    <el-upload
                        class="avatar-uploader"
                        :headers="{Authorization: 'Bearer ' + this.$store.state.auth.token}"
                        :action="apiRoot + '/api/user/avatar/update'"
                        :show-file-list="false"
                        accept="image/png,image/jpeg,image/webp"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload">
                        <img width="164" height="164" :src="this.avatarImage" class="avatar" alt="">
                        <div class="Profile-main_camera">
                            <img src="/images/camera.svg" alt="camera" width="20" height="18">
                        </div>
                    </el-upload>
                </div>
                <div class="avatar_info">
                    <p class="name">{{ account.last_name + " " + account.first_name }}</p>
                    <p class="role">{{ $t('profile.profile.role-student') }}</p>
                </div>
                <button class="Profile-main__back-btn" v-show="visibleBtnBack"
                        v-on:click="showInputs(false, false, false, false, true, false)">
                    <img src="/images/profile/arrow-back.svg" width="9" height="15" alt="">
                </button>
                <div class="Profile-main__resident">
                    <div class="checkbox-block">
                        <div class="form-check pl-0">
                            <el-radio :label="'1'" v-model="account.is_resident">{{ $t("signup.resident") }}</el-radio>
                        </div>
                        <div class="form-check">
                            <el-radio :label="'0'" v-model="account.is_resident">{{ $t("signup.non-resident") }}</el-radio>
                        </div>
                    </div>
                </div>
            </div>

            <div class="Profile-main__btns-mob" v-show="visibleMenuBtns">
                <button class="Profile-main__btn" v-on:click="showInputs(true, false, false, true, false, true)">
                    <img src="/images/profile/main-icon.svg" alt="">
                    {{ $t('profile.profile.main-info') }}
                </button>

                <button class="Profile-main__btn" v-on:click="showInputs(false, true, false, true, false, true)">
                    <img src="/images/profile/education-icon.svg" alt="">
                    {{ $t('profile.profile.education') }}
                </button>

                <button class="Profile-main__btn" v-on:click="showInputs(false, false, true, true, false, true)">
                    <img src="/images/profile/parent-icon.svg" alt="">
                    {{ $t('profile.profile.parent-mob') }}
                </button>
            </div>

            <div class="Profile-main_support white-block">
                <img src="/images/profile-main-support.svg" alt="">
                <div class="Profile-main_support_text">
                    <p class="support_title">{{ $t('profile.profile.help') }}</p>
                    <p class="support_subtitle">
                        {{ $t('profile.profile.help-desc') }}
                    </p>
                </div>
                <div class="write-to-support-button">
                    {{ $t('profile.profile.help-letter') }}
                </div>
            </div>
        </div>
        <div class="Profile-main_right" v-loading="loadingService">
            <div class="photo_recommendations white-block">
                <div class="photo_recommendations_text">
                    <p class="photo_recommendations_title">{{ $t('profile.profile.recommendations-photo') }}</p>
                    <p class="photo_recommendations_content">{{
                            $t('profile.profile.recommendations-photo-1')
                        }}<br/>{{
                            $t('profile.profile.recommendations-photo-2')
                        }}<br/>{{
                            $t('profile.profile.recommendations-photo-3')
                        }}<br/>{{ $t('profile.profile.recommendations-photo-4') }}</p>
                    <p class="photo_recommendations_attention">{{ $t('profile.profile.recommendations-photo-attention') }}</p>
                </div>
            </div>
            <form @submit.prevent="updateProfile()">
                <div class="student_info white-block">
                    <ul class="student_info__list">
                        <li class="student_info__item" v-on:click="setActive('main'); showInputs(true, false, false, true)"
                            :class="{ 'active-item': isActive('main') }">
                            {{ $t('profile.profile.main-info') }}
                        </li>
                        <li class="student_info__item" v-on:click="setActive('education'); showInputs(false, true, false, true)"
                            :class="{ 'active-item':isActive('education') }">
                            {{ $t('profile.profile.education') }}
                        </li>
                        <li class="student_info__item" v-on:click="setActive('parent'); showInputs(false, false, true, true)"
                            :class="{ 'active-item': isActive('parent') }">
                            {{ $t('profile.profile.parent') }}
                        </li>
                    </ul>
                    <div>
                        <div class="row py-5 mt-3" v-show="visibleMain">
                            <div class="student_info__title-mob">{{ $t('profile.profile.main-info') }}</div>
                            <div class="col-lg-4 col-md-12">
                                <form-group :label="$t('references.required-field') + $t('profile.profile.iin-label')" name="iin">
                                    <el-input
                                        maxlength="12"
                                        :placeholder="$t('profile.profile.iin-label')"
                                        disabled
                                        v-model="account.iin"
                                        show-word-limit>
                                    </el-input>
                                </form-group>
                                <form-group :label="$t('references.required-field') + $t('profile.profile.last-name-label')"
                                            name="last_name">
                                    <el-input
                                        disabled
                                        :placeholder="$t('profile.profile.last-name-label')"
                                        v-model="account.last_name"
                                        @change="$v.account.last_name.$touch()"
                                        clearable
                                    >
                                    </el-input>
                                </form-group>
                                <form-group :label="$t('references.required-field') + $t('profile.profile.first-name-label')"
                                            name="first_name">
                                    <el-input
                                        disabled
                                        :placeholder="$t('profile.profile.first-name-label')"
                                        v-model="account.first_name"
                                        @change="$v.account.first_name.$touch()"
                                        clearable
                                    >
                                    </el-input>
                                </form-group>
                                <form-group :label="$t('profile.profile.patronymic-label')" name="patronymic">
                                    <el-input
                                        disabled
                                        :placeholder="$t('profile.profile.patronymic-placeholder')"
                                        v-model="account.patronymic"
                                        clearable>
                                    </el-input>
                                </form-group>
                            </div>
                            <div class="col-lg-4 col-md-12">

                                <div class="birth-date-picker">
                                    <div class="birth-date-picker__label">
                                        {{ $t('references.required-field') }}{{ $t('signup.birth-date-label') }}
                                    </div>
                                    <el-input
                                        v-model="account.birthdate"
                                        disabled
                                    >
                                    </el-input>
                                </div>

                                <form-group :label="$t('references.required-field') + $t('references.gender')" name="sex"
                                            class="mob-hidden">
                                    <el-select
                                        disabled
                                        :placeholder="$t('references.gender')"
                                        v-model="account.sex">
                                        <el-option
                                            v-for="item in genders"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </form-group>
                                <form-group :label="$t('references.required-field') + $t('profile.profile.email-label')" name="email">
                                    <el-input
                                        id="email"
                                        :placeholder="$t('profile.profile.email-placeholder')"
                                        v-model="account.email"
                                        @change="$v.account.email.$touch()"
                                        clearable>
                                    </el-input>
                                </form-group>
                                <form-group :label="$t('references.required-field') + $t('profile.profile.phone-label')"
                                            name="phone">
                                    <el-input
                                        :placeholder="$t('profile.profile.phone-placeholder')"
                                        v-model="phone"
                                        v-mask="'+7 (###) ### ##-##'"
                                        @change="$v.phone.$touch()"
                                        @input="enforcePhoneFormat"
                                        clearable>
                                    </el-input>
                                </form-group>
                            </div>
                            <div class="col-lg-4 col-md-12">
                                <form-group :label="$t('references.required-field') + $t('profile.profile.region-label')"
                                            name="region_id">
                                    <el-select
                                        :placeholder="$t('profile.profile.region-placeholder')"
                                        v-model="account.education.region_id"
                                        :loading="regionsLoading"
                                        @change="getCities"
                                        filterable>
                                        <el-option
                                            v-for="item in regions"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </form-group>
                                <form-group :label="$t('references.required-field') + $t('profile.profile.district-label')"
                                            name="city_id">
                                    <el-select
                                        :placeholder="$t('profile.profile.district-placeholder')"
                                        v-model="account.education.city_id"
                                        :loading="citiesLoading"
                                        @change="getLocalities"
                                        filterable>
                                        <el-option
                                            v-for="item in cities"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </form-group>
                                <form-group :label="$t('references.required-field') + $t('profile.profile.city-label')"
                                            name="locality_id"
                                >
                                    <el-select
                                        :placeholder="$t('profile.profile.city-placeholder')"
                                        :loading="localitiesLoading"
                                        autocomplete="nope"
                                        v-model="account.education.locality_id"
                                        @change="getSchools"
                                        filterable>
                                        <el-option
                                            v-for="item in localities"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </form-group>
                                <form-group :label="$t('references.required-field') + $t('profile.profile.organization-name-label')">
                                    <el-select
                                        v-model="account.education.school_id"
                                        :placeholder="$t('profile.profile.organization-name-placeholder')"
                                        filterable>
                                        <el-option
                                            :no-match-text="$t('references.schools-not-found')"
                                            :no-data-text="$t('references.schools-not-found')"
                                            v-for="item in schools"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </form-group>
                            </div>
                        </div>
                        <div class="row py-5 mt-3 justify-content-center" v-show="visibleEducation">
                            <div class="student_info__title-mob">{{ $t('profile.profile.education') }}</div>
                            <div class="col-lg-4 col-md-12">
                                <form-group :label="$t('references.required-field') + $t('profile.profile.organization-name-2-label')">
                                    <el-select
                                        v-model="account.education.school_id"
                                        :placeholder="$t('profile.profile.organization-name-placeholder')"
                                        filterable>
                                        <el-option
                                            :no-match-text="$t('references.schools-not-found')"
                                            :no-data-text="$t('references.schools-not-found')"
                                            v-for="item in schools"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </form-group>
                                <form-group :label="$t('references.required-field') + $t('profile.profile.class-label')"
                                            name="class">
                                    <el-select
                                        autocomplete="nope"
                                        v-model="account.education.class"
                                        :placeholder="$t('profile.profile.class-placeholder')"
                                    >
                                        <el-option
                                            :key="5"
                                            :label="5+$t('profile.profile.class')"
                                            :value="5">
                                        </el-option>
                                        <el-option
                                            :key="6"
                                            :label="6+$t('profile.profile.class')"
                                            :value="6">
                                        </el-option>
                                    </el-select>
                                </form-group>
                                <form-group :label="$t('references.required-field') + $t('profile.profile.education-language-label')"
                                            name="education_lang">
                                    <el-select
                                        autocomplete="nope"
                                        v-model="account.education.education_lang"
                                        :placeholder="$t('profile.profile.education-language-label')"
                                    >
                                        <el-option
                                            :key="1"
                                            :label="$t('profile.profile.language-kz')"
                                            :value="'kz'">
                                        </el-option>
                                        <el-option
                                            :key="2"
                                            :label="$t('profile.profile.language-ru')"
                                            :value="'ru'">
                                        </el-option>
                                        <el-option
                                            :key="3"
                                            :label="$t('profile.profile.language-en')"
                                            :value="'en'">
                                        </el-option>
                                    </el-select>
                                </form-group>
                            </div>
                            <div class="col-lg-4 col-md-12">
                                <form-group :label="$t('references.required-field') + $t('profile.profile.test-language-label')"
                                            name="test_lang">
                                    <el-select
                                        autocomplete="nope"
                                        v-model="account.education.test_lang"
                                        :placeholder="$t('profile.profile.test-language-label')"
                                    >
                                        <el-option
                                            :key="1"
                                            :label="$t('profile.profile.language-kz')"
                                            :value="'kz'">
                                        </el-option>
                                        <el-option
                                            :key="2"
                                            :label="$t('profile.profile.language-ru')"
                                            :value="'ru'">
                                        </el-option>
                                    </el-select>
                                </form-group>
                                <form-group :label="$t('references.required-field') + $t('profile.profile.change_of_training-label')"
                                            name="change_of_training_id">
                                    <el-select
                                        autocomplete="nope"
                                        v-model="account.education.shift"
                                        :placeholder="$t('profile.profile.change_of_training-placeholder')">
                                        <el-option
                                            :key="1"
                                            :label="1+' '+$t('profile.profile.change_of_training')"
                                            :value="1">
                                        </el-option>
                                        <el-option
                                            :key="2"
                                            :label="2+' '+$t('profile.profile.change_of_training')"
                                            :value="2">
                                        </el-option>
                                        <el-option
                                            :key="3"
                                            :label="3+' '+$t('profile.profile.change_of_training')"
                                            :value="3">
                                        </el-option>
                                    </el-select>
                                </form-group>
                                <form-group :label="$t('profile.profile.other-nonresident-label')" name="other">
                                    <el-input
                                        :placeholder="$t('profile.profile.other-nonresident-placeholder')"
                                        v-model="account.education.additional_info"
                                        clearable
                                    >
                                    </el-input>
                                </form-group>
                            </div>
                        </div>
                        <div class="row py-5 mt-3 justify-content-center" v-show="visibleParent">
                            <div class="student_info__title-mob">{{ $t('profile.profile.parent') }}</div>
                            <div class="col-lg-4 col-md-12">
                                <form-group :label="$t('references.required-field') + $t('profile.profile.parent-last-name-label')"
                                            name="parent_last_name">
                                    <el-input
                                        :placeholder="$t('profile.profile.last-name-placeholder')"
                                        v-model="account.parent.parent_last_name"
                                        clearable>
                                    </el-input>
                                </form-group>
                                <form-group :label="$t('references.required-field') + $t('profile.profile.parent-first-name-label')"
                                            name="parent_first_name">
                                    <el-input
                                        :placeholder="$t('profile.profile.first-name-placeholder')"
                                        v-model="account.parent.parent_first_name"
                                        clearable>
                                    </el-input>
                                </form-group>
                                <form-group :label="$t('profile.profile.parent-patronymic-label')"
                                            name="parent_patronymic">
                                    <el-input
                                        :placeholder="$t('profile.profile.patronymic-placeholder')"
                                        v-model="account.parent.parent_patronymic"
                                        clearable>
                                    </el-input>
                                </form-group>
                            </div>
                            <div class="col-lg-4 col-md-12">
                                <form-group :label="$t('references.required-field') + $t('profile.profile.parent-phone-label')"
                                            name="parent_phone">
                                    <el-input
                                        :placeholder="$t('profile.profile.phone-placeholder')"
                                        v-model="parentPhone"
                                        v-mask="'+7 (###) ### ##-##'"
                                        @input="enforceParentPhoneFormat"
                                        clearable>
                                    </el-input>
                                </form-group>
                                <form-group :label="$t('references.required-field') + $t('profile.profile.parent-add-phone-label')"
                                            name="parent_add_phone">
                                    <el-input
                                        :placeholder="$t('profile.profile.phone-placeholder')"
                                        v-model="parentAddPhone"
                                        v-mask="'+7 (###) ### ##-##'"
                                        @input="enforceParentAdditionalPhoneFormat"
                                        clearable>
                                    </el-input>
                                </form-group>
                            </div>
                        </div>
                    </div>
                    <button type="submit" class="student_info_save btn_lila" v-show="visibleSaveBtn">
                        {{ $t('profile.profile.save-btn') }}
                    </button>
                </div>
            </form>
        </div>

        <div class="Profile-main_support Profile-main_support--mob white-block" v-loading="loadingService">
            <img src="/images/profile-main-support.svg" alt="">
            <div class="Profile-main_support_text">
                <p class="support_title">{{ $t('profile.profile.help') }}</p>
                <p class="support_subtitle">
                    {{ $t('profile.profile.help-desc') }}
                </p>
            </div>
            <div class="write-to-support-button">
                {{ $t('profile.profile.help-letter') }}
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { minLength, required } from "vuelidate/lib/validators";
import VueMask from 'v-mask'
import Datepicker from 'vuejs-datepicker';
import {en, ru, kk} from 'vuejs-datepicker/dist/locale'

export default {
    beforeCreate() {
        Vue.use(VueMask);
    },
    components: {
        Datepicker
    },
    data() {
        return {
            loadingService: false,
            apiRoot: window.API_ROOT,
            en: en,
            ru: ru,
            kk: kk,
            phone: '',
            parentPhone: '',
            parentAddPhone: '',
            schools: [],
            regions: [],
            cities: [],
            localities: [],
            schoolsLoading: false,
            regionsLoading: false,
            citiesLoading: false,
            localitiesLoading: false,
            loading:false,
            avatar:'',
            avatarImage:'',
            account: {
                iin: '',
                first_name: '',
                last_name: '',
                is_resident: '',
                patronymic: '',
                email: '',
                phone: '',
                birthdate: '',
                change_of_training_id: '',
                other: '',
                sex: '',
                education: {
                    school_id: '',
                    region_id: '',
                    district_id: '',
                    locality_id: '',
                    city_id: '',
                    class: '',
                    education_lang: '',
                    test_lang: '',
                    shift: '',
                    additional_info: ''
                },
                parent: {
                    parent_last_name: '',
                    parent_first_name: '',
                    parent_patronymic: '',
                    parent_phone: '',
                    parent_additional_phone: '',
                },
            },
            genders: [
                {
                    id: 1,
                    name: this.$t('references.gender-male')
                },
                {
                    id: 2,
                    name: this.$t('references.gender-female')
                }
            ],
            visibleSaveBtn: true,
            visibleMenuBtns: true,
            visibleBtnBack: false,
            visibleMain: false,
            visibleEducation: false,
            visibleParent: false,
            activeItem: 'main'
        }
    },
    validations: {
        account: {
            is_resident: { required },
            email: { required },
            phone: { required },
            education: {
                region_id: { required },
                city_id: { required },
                locality_id: { required },
                school_id: { required },
                class: { required },
                education_lang: { required },
                test_lang: { required },
                shift: { required },
            },
            parent: {
                parent_last_name: { required },
                parent_first_name: { required },
                parent_phone: { required },
                parent_additional_phone: { required },
            }
        },
        phone: {
            required: false,
            minLength: minLength(18)
        },
        parentPhone: {
            required: false,
            minLength: minLength(18)
        },
        parentAddPhone: {
            required: false,
            minLength: minLength(18)
        },
    },
    mounted: function () {
        this.loadingService = true;
        setTimeout(() => {
            this.loadingService = false;
        }, 5000);
        this.getRegions();
        this.$http.get(window.API_ROOT + '/api/user/edit')
            .then((res) => {
                sessionStorage.setItem('schedule-link', res.data.scheduleLink);
                // if(res.data.data.session_id === localStorage.getItem('session_id')) {

                this.account.education.region_id = res.body.data.education.region_id;
                if (this.account.education.region_id != null) {
                    this.getCities(this.account.education.region_id, false)
                }
                this.account.education.city_id = res.body.data.education.district_id;
                if (this.account.education.city_id != null) {
                    this.getLocalities(this.account.education.city_id, false)
                }
                this.account.education.locality_id = res.body.data.education.locality_id;
                if (this.account.education.locality_id != null) {
                    this.getSchools(this.account.education.locality_id, false)
                }
                this.account.education.school_id = res.body.data.education.school_id;

                let user = res.body.data;
                this.avatar = user.avatar;
                this.avatarImage=window.API_ROOT + '/api/user/avatar?file=' + this.avatar
                this.account.iin = user.iin;
                this.account.email = user.email;
                this.account.birthdate = user.birthdate;
                this.account.first_name = user.first_name;
                this.account.last_name = user.last_name;
                this.account.patronymic = user.patronymic;
                this.account.is_resident = user.is_resident.toString();
                this.account.sex = user.sex;

                this.phone = user.phone;
                this.$v.phone.$touch();

                this.parentPhone = user.parent.parent_phone;
                this.$v.parentPhone.$touch();

                this.parentAddPhone = user.parent.parent_additional_phone;
                this.$v.parentAddPhone.$touch();

                this.account.education.class = user.education.class;
                this.account.education.education_lang = user.education.education_lang;
                this.account.education.test_lang = user.education.test_lang;
                this.account.education.shift = user.education.shift;
                this.account.education.additional_info = user.education.additional_info;

                this.account.parent.parent_first_name = user.parent.parent_first_name;
                this.account.parent.parent_last_name = user.parent.parent_last_name;
                this.account.parent.parent_patronymic = user.parent.parent_patronymic;
                this.account.parent.parent_phone = user.parent.parent_phone;
                this.account.parent.parent_additional_phone = user.parent.parent_additional_phone;

                this.region_id = user.education.region_id

                localStorage.setItem('user', JSON.stringify(res.data.data))
                this.avatar = user.avatar;
                sessionStorage.setItem('avatar-url', user.avatar);
                this.loadingService = false
            })
            .catch((e) => {
                this.loadingService = false
                if (e.status === 401 || e.status === 403) {
                    this.$router.push('/')
                }
            });

        if ($('body').width() <= 992) {
            this.visibleMain = false;
            this.visibleEducation = false;
            this.visibleParent = false;
            this.visibleSaveBtn = false;
            this.visibleBtnBack = false;
        } else {
            this.visibleMain = true;
            this.visibleEducation = false;
            this.visibleParent = false;
            this.visibleSaveBtn = true;
            this.visibleMenuBtns = true;
            this.visibleBtnBack = false;
        }
    },
    methods: {
        handleAvatarSuccess(res, file) {
            this.$router.go()
            this.avatarUrl = URL.createObjectURL(file.raw);
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isPNG = file.type === 'image/png';
            const isLt10M = file.size / 1024 / 1024 < 10;

            if (!(isJPG || isPNG)) {
                this.$message.error('Avatar picture must be JPG or PNG format!');
            }
            if (!isLt10M) {
                this.$message.error('Avatar picture size can not exceed 10MB!');
            }
            return (isJPG || isPNG) && isLt10M;
        },
        getRegions(reset = true) {
            this.regionsLoading = true;
            this.$http.get(window.API_ROOT + '/api/reference/regions')
                .then((res) => {
                    this.regionsLoading = false;
                    if (res.body.data) {
                        this.regions = res.body.data;
                        if (reset) {
                            this.account.education.region_id = '';
                        }
                    }
                }).catch(() => {
                this.citiesLoading = false;
            })
        },
        getCities(id, reset = true) {
            this.citiesLoading = true;
            this.$http.get(window.API_ROOT + '/api/reference/districts/' + id)
                .then((res) => {
                    this.citiesLoading = false;
                    if (res.body.data) {
                        this.cities = res.body.data;
                        if (reset) {
                            this.account.education.city_id = '';
                        }
                    }
                }).catch(() => {
                    this.citiesLoading = false;
                })
        },
        getLocalities(id, reset = true) {
            this.localitiesLoading = true;
            this.$http.get(window.API_ROOT + '/api/reference/localities/' + id)
                .then((res) => {
                    this.localitiesLoading = false;
                    if (res.body.data) {
                        this.localities = res.body.data;
                        if (reset) {
                            this.account.education.locality_id = '';
                        }
                    }
                }).catch(() => {
                    this.localitiesLoading = false;
                })
        },
        getSchools(id, reset = true) {
            this.schoolsLoading = true;
            this.$http.get(window.API_ROOT + '/api/reference/schools/' + id)
                .then((res) => {
                    this.schoolsLoading = false;
                    if (res.body.data) {
                        this.schools = res.body.data;
                        if (reset) {
                            this.account.education.school_id = '';
                        }
                    }
                }).catch(() => {
                this.schoolsLoading = false;
            })
        },
        updateProfile() {
            this.$v.account.$touch();
            if (this.$v.account.$invalid) {
                Vue.toastr({
                    message: 'Ошибка',
                    description: 'Заполните все поля',
                    type: 'error'
                });
            } else {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    Vue.toastr({
                        message: 'Ошибка',
                        description: 'Проверьте правильность всех полей',
                        type: 'error'
                    });
                    return
                }
                this.$http.post(window.API_ROOT + '/api/user/update', this.account)
                    .then((res) => {
                        Vue.toastr({
                            message: 'Успешно!',
                            description: res.data.success,
                            type: 'success'
                        });
                    }).catch((e) => {
                        Vue.toastr({
                            message: 'Ошибка',
                            description: e.body.errors[0],
                            type: 'error'
                        });
                    });
            }
        },
        enforcePhoneFormat() {
            this.$v.phone.$touch();
            let x = this.phone
                .replace(/\D/g, "")
                .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

            this.account.phone = x[0];
        },
        enforceParentPhoneFormat() {
            this.$v.parentPhone.$touch();
            let x = this.parentPhone
                .replace(/\D/g, "")
                .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

            this.account.parent.parent_phone = x[0];
        },
        enforceParentAdditionalPhoneFormat() {
            this.$v.parentAddPhone.$touch();
            let x = this.parentAddPhone
                .replace(/\D/g, "")
                .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

            this.account.parent.parent_additional_phone = x[0];
        },
        showInputs(visibleMain, visibleEducation, visibleParent, visibleSaveBtn, visibleMenuBtns, visibleBtnBack) {
            this.visibleMain = visibleMain;
            this.visibleEducation = visibleEducation;
            this.visibleParent = visibleParent;
            this.visibleSaveBtn = visibleSaveBtn;
            this.visibleMenuBtns = visibleMenuBtns;
            this.visibleBtnBack = visibleBtnBack;
        },
        isActive: function (menuItem) {
            return this.activeItem === menuItem
        },
        setActive: function (menuItem) {
            this.activeItem = menuItem
        },
    }
}
</script>
<style lang="less" scoped>
.Profile-main {
    display: flex;
    justify-content: space-between;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 50px;
    max-width: 100%;
}

.Profile-main .white-block {
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
    padding: 26px 17px;
    border-radius: 8px;
}

.Profile-main .Profile-main_left {
    display: flex;
    flex-flow: column;
    width: 27%;
}

.Profile-main .Profile-main_left .Profile-main_photo {
    display: flex;
    flex-flow: column;
    align-items: center;
}

.Profile-main .Profile-main_left .Profile-main_photo .photo_title {
    color: #303030;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    opacity: 0.5;
}

.Profile-main .Profile-main_left .Profile-main_photo img {
    border-radius: 100px;
}

.Profile-main .Profile-main_left .Profile-main_photo .avatar_info {
    text-align: center;
    margin-top: 43px;
}

.Profile-main .Profile-main_left .Profile-main_photo .avatar_info p {
    margin: 0
}

.Profile-main .Profile-main_left .Profile-main_photo .avatar_info .name {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
}

.Profile-main_support {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-top: 16px;
}

.Profile-main_support .Profile-main_support_text {
    margin: 40px 0 63px;
}

.Profile-main_support .Profile-main_support_text .support_title {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #303030;
    text-transform: uppercase;
}

.Profile-main_support .Profile-main_support_text .support_subtitle {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #303030;
    font-family: 'Roboto Condensed', sans-serif;
}

.Profile-main_support .write-to-support-button {
    background: #473F95;
    border-radius: 5px;
    margin-bottom: 14px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
    padding: 11px 28.5px;
    cursor: pointer;
}

.Profile-main_right {
    width: 71%;
}

.Profile-main_right .photo_recommendations {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 16px;
    // margin-right: 50px;
}

.Profile-main_right .photo_recommendations .photo_recommendations_title {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #473F95;
}

.Profile-main_right .photo_recommendations .photo_recommendations_content {
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #473F95;
}

.Profile-main_right .photo_recommendations .photo_recommendations_attention {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #D23168;
}

.Profile-main_camera {
    background: #473F95;
    width: 49px;
    height: 49px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -6px;
    right: 0;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    border: none;
}

.nav-tabs {
    border-bottom: none;
}

.nav-tabs .nav-link {
    height: 100%;
    border-bottom: 2px solid #EEEEEE;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #303030;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding: 4px 16px;
}

.nav-tabs .nav-link.active {
    border-bottom: 2px solid #473F95;
    color: #473F95;
}

/deep/ .nav-tabs .nav-link:hover {
    border-bottom: 2px solid #EEEEEE;
    border-color: transparent transparent #473F95;
}

.form-label {
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.student_info_save {
    padding: 14px 38px;
    display: flex;
    margin: 0 auto;
}

.el-date-table td span {
    width: 38px;
    height: 38px;
    line-height: 38px;
    border: 1px solid #EDF2F7;
    font-size: 14px;
}

.el-date-table td {
    width: 38px;
    height: 38px;
}

.el-date-table td.today span {
    color: #D23168;
}

.el-date-table td:hover span {
    color: #D23168;
}

.el-date-table td.current:not(.disabled) span {
    background-color: #473F95;
}

.el-date-table__row {
    height: 44px;
}

.Profile-main__btns-mob {
    display: none;
}

/deep/ .student_info__list {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

/deep/ .student_info__item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
    height: 100%;
    border-bottom: 2px solid #EEEEEE;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #303030;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding: 4px 16px;
    min-height: 48px;
    cursor: pointer;
}

/deep/ .student_info__item.active-item {
    border: none;
    border-bottom: 2px solid #473F95;
    color: #473F95;
}

.Profile-main__back-btn {
    display: none;
}

.Profile-main .Profile-main_support--mob {
    display: none;
}

.student_info__title-mob {
    display: none;
}

.Profile-main__resident .checkbox-block {
    display: flex;
    margin-top: 54px;
}

/deep/ .Profile-main__resident .el-radio {
    display: flex;
    align-items: center;
}

/deep/ .Profile-main__resident .el-radio__label {
    color: #2C2C2C;
}

/deep/ .Profile-main__resident .el-radio__input.is-checked .el-radio__inner {
    border-color: #5E57A2;
    background: #5E57A2;
}

/deep/ .Profile-main__resident .el-radio__inner:hover {
    border-color: #5E57A2;
}

/deep/ .Profile-main__resident .el-radio__inner {
    width: 16px;
    height: 16px;
}

/deep/ .Profile-main__resident .el-radio__inner::after {
    width: 8px;
    height: 8px;
}

/deep/ .profile-birth-date-picker .profile-birth-date-picker__input {
    -webkit-appearance: none;
    background-color: #FFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
    width: 100%;
    margin-bottom: 16px;
}

/deep/ .profile-birth-date-picker .profile-birth-date-picker__input:hover {
    border-color: #C0C4CC;
}

/deep/ .profile-birth-date-picker .profile-birth-date-picker__input::placeholder {
    font-size: 14px;
    color: #c4c7ce;
    opacity: 1;
}

/deep/ .profile-birth-date-picker .profile-birth-date-picker__input:-ms-input-placeholder {
    color: #c4c7ce;
}

/deep/ .profile-birth-date-picker .profile-birth-date-picker__input::-ms-input-placeholder {
    color: #c4c7ce;
}

/deep/ .profile-birth-date-picker .vdp-datepicker__calendar {
    border: none;
    width: 336px;
    box-shadow: 0 4px 4px rgb(51 51 51 / 4%), 0 4px 16px rgb(51 51 51 / 8%);
    border-radius: 4px;
    padding: 12px 14px;
}

/deep/ .profile-birth-date-picker .day__month_btn {
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
}

/deep/ .profile-birth-date-picker .vdp-datepicker__calendar header .prev:not(.disabled):hover,
/deep/ .profile-birth-date-picker .vdp-datepicker__calendar header .next:not(.disabled):hover,
/deep/ .profile-birth-date-picker .vdp-datepicker__calendar header .up:not(.disabled):hover {
    background-color: #fff;
}

/deep/ .profile-birth-date-picker .vdp-datepicker__calendar header .prev:after {
    border-right: 10px solid #473F95;
}

/deep/ .profile-birth-date-picker .vdp-datepicker__calendar header .next:after {
    border-left: 10px solid #473F95;
}

/deep/ .profile-birth-date-picker .vdp-datepicker__calendar .cell.day-header {
    color: #232A3E;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
}

/deep/ .profile-birth-date-picker .vdp-datepicker__calendar .cell {
    border: 1px solid #EDF2F7;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 2px;
}

/deep/ .profile-birth-date-picker .vdp-datepicker__calendar .cell.day.blank {
    visibility: hidden;
}

/deep/ .profile-birth-date-picker .vdp-datepicker__calendar .cell.day-header {
    border: none;
}

/deep/ .profile-birth-date-picker .vdp-datepicker__calendar .cell.day.today {
    color: #d23168;
}

/deep/ .profile-birth-date-picker .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
/deep/ .profile-birth-date-picker .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
/deep/ .profile-birth-date-picker .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
    border: 1px solid #473F95;
}

/deep/ .profile-birth-date-picker .vdp-datepicker__calendar .cell.day.selected {
    background-color: #473F95;
    color: #fff;
}
.birth-date-picker {
    margin-bottom: 16px;
}
.birth-date-picker__label {
    margin-bottom: 0.5rem;
    font-weight: 400;
    color: #212529;
    font-size: 13px;
    line-height: 16px;
}

/deep/ .profile-birth-date-picker .vdp-datepicker__calendar .cell.month {
    border-radius: 0;
    width: 48%;
    height: auto;
    border: 1px solid #fff;
}

/deep/ .profile-birth-date-picker .vdp-datepicker__calendar .cell.year {
    border-radius: 0;
    width: 48%;
    height: auto;
    border: 1px solid #fff;
}

/deep/ .profile-birth-date-picker .vdp-datepicker__calendar .cell.selected {
    background: #473F95;
    color: #fff;
}

@media (min-width: 991px) and (max-width: 1200px) {
    .nav-tabs .nav-link {
        font-size: 13px;
        line-height: 16px;
    }

    .Profile-main__resident .checkbox-block {
        flex-direction: column;
    }

    .checkbox-block .form-check {
        padding: 0;
    }

    .student_info__item {
        padding: 4px 0;
    }
}

@media (max-width: 991px) {
  .Profile-main {
    flex-direction: column;
    margin-bottom: 10px;
  }

    .Profile-main_right {
        width: 100%;
    }

    .Profile-main_right .photo_recommendations {
        display: none;
    }

    .Profile-main .Profile-main_left {
        width: 100%;
    }

    .Profile-main_right {
        width: 100%;
    }

    .student_info__list {
        display: none;
    }

    .Profile-main__btns-mob {
        display: block;
        margin: 56px 0 36px 0;
    }

    .Profile-main__btn {
        color: #473F95;
        letter-spacing: 0.2px;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        padding: 20px;
        background: #EEEAFB;
        border-radius: 15px;
        border: none;
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
    }

    .Profile-main__btn img {
        margin-right: 34px;
    }

    .student_info.white-block {
        box-shadow: none;
        padding: 0;
    }

    .Profile-main .Profile-main_left .Profile-main_support {
        display: none;
    }

  .Profile-main .Profile-main_support--mob {
    display: block;
    margin-top: 36px;
    margin-bottom: 0;
  }

    .student_info_save {
        margin-bottom: 41px;
    }

    .student_info__title-mob {
        display: block;
        text-align: center;
        color: #473F95;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        margin: -40px auto 56px auto;
    }

    .Profile-main__back-btn {
        display: flex;
        border: none;
        background: #473F95;
        border-radius: 5px;
        width: 58px;
        height: 55px;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        top: 0;
    }

    .Profile-main .Profile-main_left .Profile-main_photo {
        position: relative;
    }
}
</style>
